export const environment = {
  version: '1-0-4',
  buildDate: '202504011123',
  environment: 'production',
  release: '9d862603622ccd66fbe9fe63b2e97eceafd98f39',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://api.catch-talents.de',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
